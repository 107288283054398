<template>
<div class="card" >
  <b-list-group >
    <b-list-group-item class="d-flex align-items-center  " style='    height: 100px;'>
      <b-avatar variant="primary" class="mr-3"></b-avatar>
      <span class="mr-auto">{{$t('labels.total_accounts')}}</span>
      <b-badge>{{ customers }}</b-badge>
    </b-list-group-item>
    <b-list-group-item class="d-flex align-items-center" style='    height: 100px;'>
      <b-avatar variant="info"  class="mr-3"></b-avatar>

      <span class="mr-auto">  {{$t('labels.total_count_of_guests_using_the_app')}}</span>
      <b-badge>{{ guest }}</b-badge>
    </b-list-group-item>
    <b-list-group-item class="d-flex align-items-center" style='    height: 100px;'>
      <b-avatar variant="danger"  text="x" class="mr-3"></b-avatar>
      <span class="mr-auto">  {{$t('labels.the_number_of_deleted_accounts')}} </span>
      <b-badge>{{ deletedAccounts }}</b-badge>
    </b-list-group-item>
  </b-list-group>

</div>

</template>

<script>
import {
  BCard, BBadge, BCardTitle, BCardText, BAvatar, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { integer } from '@core/utils/validations/validations'

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    customers: {
      type: integer,
    },
    guest: {
      type: integer,
    },
    deletedAccounts: {
      type: integer,
    },
  },
}
</script>
