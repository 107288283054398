<template>
  <b-card >
    <b-row >

      <!-- text and button -->

      <!-- dropdown and chart -->
      <b-col
        sm="12"
        cols="12"
        order-sm="2"
        order="1"
        class="d-flex justify-content-between flex-column text-right"
      >
        <div>
          <b-dropdown
            text="Last 7 Days"
            variant="transparent"
            class="chart-dropdown"
            left
            no-caret
            size="sm"
          >

          </b-dropdown>
        </div>
        <vue-apex-charts type="pie" :options="chartOptions" :series="series"></vue-apex-charts>


      </b-col>
    </b-row>


  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BDropdown, BDropdownItem, BProgress, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'
import { kFormatter } from '@core/utils/filter'
import store from '@/store'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
    BDropdown,
    BDropdownItem,
    BProgress,
  },
  directives: {
    Ripple,
  },
  props: {
    infos: {
    },
    users: {
    },
  },
  data() {
    return {



    }
  },
  computed: {
    series(){

       return this.users.slice();





    },

    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'pie',
          foreColor: store.state.appConfig.layout.skin === 'dark' ? 'white' : 'black', // Initial label color

        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 90,
            offsetY: 10
          }
        },
        labels: this.infos.slice(),

        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]

      };
    },




  },
  methods: {
    kFormatter,
    generateCategoriesData() {
      // Your logic for generating categories data goes here
      // For example, you can return an array of formatted date strings
      console.log(this.dateCate.slice())
      this.$forceUpdate();

      return this.dateCate.slice();
    },
  },

}
</script>
