<template>
  <div class="card" >
        <b-card-header class="align-items-baseline">


    <canvas id="platformChart"   width="400" height="200"></canvas>
    </b-card-header>

  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: {
    infos: {
      type:Array
    },
    users: {
      type:Array
    },
  },
  data() {
    return {
      dataSet:[],




    }
  },


  mounted() {
    this.renderChart(this.chartData, { responsive: true, maintainAspectRatio: false });

  },
  methods: {
    renderChart() {
      const ctx = document.getElementById("platformChart").getContext("2d");

      // console.log(this.countries)

      this.platformChart =  new Chart(ctx, {
        type: "doughnut",
        data: {

          labels: this.infos.slice() ,
          datasets: [
            {
              data: this.users.slice(),
              backgroundColor: ['#9958c2', '#9a79af'],
              hoverBackgroundColor:['#9958c2', '#9a79af'],
              borderWidth: 0,
            },
          ],
        },
        options: {
          title: {
            display: true,
            text: this.$t('labels.active_users_by_platform')
          },
          scales: {
            y: {
              beginAtZero: true,
            },

          },
        },

      });
    },

    refreshPlatformChart() {
      // Destroy the existing chart
      this.platformChart.destroy();
      // Re-render the chart
      this.renderChart();
    },

  },
};
</script>

<style scoped>
/* Add your component styles here */
</style>
