<template>
  <b-card >
    <b-row >

      <b-col
        sm="12"
        cols="12"
        order-sm="2"
        order="1"
        class="d-flex justify-content-between flex-column text-right"
      >
        <div>
          <b-dropdown
            :text="$t('labels.last_7_days')"
            variant="transparent"
            class="chart-dropdown"
            left
            no-caret
            size="sm"
          >

          </b-dropdown>
        </div>

        <vue-apex-charts
          type="line"
          height="300"
          :options="chartOptions"
          :series="series"
        />
      </b-col>
    </b-row>


  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BDropdown, BDropdownItem, BProgress, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'
import { kFormatter } from '@core/utils/filter'
import store from '@/store'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
    BDropdown,
    BDropdownItem,
    BProgress,
  },
  directives: {
    Ripple,
  },
  props: {
    newUsers: {
    },
    dateCate: {
    },
  },
  data() {
    return {



    }
  },
  computed: {
    series(){

       return [ { "name": this.$t('labels.new_users'), "data": this.newUsers.slice(),
       } ];





    },

    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'line',
          foreColor: store.state.appConfig.layout.skin === 'dark' ? 'white' : 'black', // Initial label color

        },

        forecastDataPoints: {
          count: 14
        },
        stroke: {
          width: 5,
          curve: 'smooth'
        },
        xaxis: {
          type: 'data',
          // type: 'linear',
          categories: this.dateCate.slice(),
          tickAmount: 17,

        },
        title: {
          text: this.$t('labels.new_users'),
          align: 'center',
          style: {
            fontSize: "16px",
            color: '#666'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: [ $themeColors.primary],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          },
        },

      };
    },




  },
  methods: {
    kFormatter,

  },

}
</script>
